import { useEffect, useState } from 'react';
import { isAfter, isBefore } from 'date-fns';

export const useDateToggle = (envVarValue) => {
    const [isDatePassed, setIsDatePassed] = useState(null);

    useEffect(() => {
        const currentDate = new Date();

        const dateParts = envVarValue.split('-');
        const targetDate = new Date(
            dateParts[0],
            dateParts[1] - 1,
            dateParts[2],
            dateParts[3] ? dateParts[3] : '',
            dateParts[4] ? dateParts[4] : ''
        );

        const hasDatePassed = isAfter(currentDate, targetDate);
        
        setIsDatePassed(hasDatePassed);
    }, [envVarValue]);

    return isDatePassed;
};

export const useDatePeriodToggle = (startDate, endDate) => {
    const [isDateInRange, setIsDateInRange] = useState(null);

    useEffect(() => {
        const currentDate = new Date();

        const startParts = startDate.split('-');
        const endParts = endDate.split('-');

        const start = new Date(
            startParts[0],
            startParts[1] - 1,
            startParts[2],
            startParts[3] ? startParts[3] : '',
            startParts[4] ? startParts[4] : ''
        );

        const end = new Date(
            endParts[0],
            endParts[1] - 1,
            endParts[2],
            endParts[3] ? endParts[3] : '',
            endParts[4] ? endParts[4] : ''
        );

        const isInRange = isAfter(currentDate, start) && isBefore(currentDate, end);
        setIsDateInRange(isInRange);
    }, [startDate, endDate]);

    return isDateInRange;
};

export const useSupagasInfoV3 = () => {
    return useDateToggle(process.env.SUPAGAS_INFO_V3);
};

export const useSummerCampaignActive = () => {
    const startDate = process.env.SUMMER_CAMPAIGN_START;
    const endDate = process.env.SUMMER_CAMPAIGN_END;
    return useDatePeriodToggle(startDate, endDate);
};